<template>
    <div class="projectDetail">
        <div class="projectDetail-breadcrumb">
            <div class="box">
                <span style="cursor: pointer;" @click="toHome">首页</span><i class="el-icon-arrow-right back"></i>项目列表
            </div>
        </div>
        <div class="projectDetail-header">
            <div class="projectDetail-header-container">
                <el-image :src="detail.cover" class="cover" fit="fill" :key="detail.cover">
                    <div slot="error" class="error">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
                <div>
                    <p class="title">{{ detail.enterpriseName }}</p>
                    <div class="industryName">
                        <span v-for="tag in (detail.industryName || '').split(',').filter(i => i)" :key="tag">{{ tag }}</span>
                    </div>
                    <p class="projectDescription">项目简介：{{ detail.projectDescription ? detail.projectDescription : '暂无' }}</p>
                    <p class="businessIncomeName">
                        <span><img src="@/assets/dollar.png" alt="">营收：{{detail.businessIncomeName || '--'}}</span>
                        <i class="line">|</i>
                        <span><img src="@/assets/dollar.png" alt="">净利润：{{detail.netProfitName || '--'}}</span>
                        <i class="line">|</i>
                        <span><i class="el-icon-location-outline icon"></i>地址：{{detail.provinceName || detail.cityName ? `${detail.provinceName && detail.cityName ? `${detail.provinceName}${detail.cityName}` : detail.provinceName || detail.cityName}` : '--'}}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="projectDetail-body">
            <div class="projectDetail-body-item" v-for="(item, index) in tabOptions" :key="index">
                <p class="title">{{ item.label }}</p>
                <div class="file-list">
                    <template v-if="!!getFilest(item.value).slice(0, 2).length && item.value !== 6">
                        <div class="file-list-item" v-for="items in getFilest(item.value).slice(0, 2)" :key="items.id" :style="{backgroundColor: items.fileType == 1 ? '#FFEFE8' : items.fileType == 2 ? '#E8F6FF' : '#FFF2F2'}">
                            <img class="cover" :src="coverMap[items.fileType]" alt="">
                            <div class="right">
                                <div class="file-title">
                                    <span>{{items.originName || items.fileName}}</span>
                                    <div class="public" v-if="items.isPublic">公开</div>
                                </div>
                                <div class="file-size">
                                    {{formatSize(items.fileSize || 0)}}
                                    <div class="operate">
                                        <span  @click="preview(items)">预览</span>
                                        <i>|</i>
                                        <span  @click="downLoad(items)">下载</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="!!getFilest(item.value).slice(0, 1).length && item.value == 6">
                        <div class="file-list-enterpriseConcat"  v-for="items in getFilest(item.value).slice(0, 1)" :key="items.id">
                            <div style="margin-right: 47px;">
                                <el-badge value="公开" :hidden="!items.isPublic">
                                    <img class="user" src="@/assets/project/user.png" style="width: 72px;height:72px;" mode="" />
                                </el-badge>
                            </div>
                            <div class="line">
                                <img class="icon" src="@/assets/project/user1.png" mode="" />
                                <span class="name">姓名</span>
                                {{items.enterpriseConcatName}}
                            </div>
                            <div class="line">
                                <img class="icon" src="@/assets/project/tel.png" mode="" />
                                <span class="name">手机号</span>
                                {{items.enterpriseConcatPhone}}
                            </div>
                            <div class="line">
                                <img class="icon" src="@/assets/project/position.png" mode="" />
                                <span class="name">职务</span>
                                {{items.enterpriseConcatPosition || '---'}}
                            </div>
                            <div class="line line-card">
                                <img class="icon" src="@/assets/project/tel.png" mode="" />
                                <span class="name">名片</span>
                                <el-button class="showConcate" type="primary" size="mini" @click="lookEnterpriseConcat(items)" :loading="publicUserLoading">查看</el-button>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <el-empty :image-size="70" description="暂无数据"></el-empty>
                    </template>
                </div>
            </div>
        </div>
        <!-- 公开联系人查看 -->
        <el-dialog
            title="查看联系人"
            :visible.sync="publicUserVisible"
            width="400px">
            <p class="publicUser-line">姓名：{{ publicUserData.enterpriseConcatName }}</p>
            <p class="publicUser-line">手机号：{{ publicUserData.enterpriseConcatPhone }}</p>
            <p class="publicUser-line">职务：{{ publicUserData.enterpriseConcatPosition }}</p>
            <p class="publicUser-line">
                名片：
                <img v-if="publicUserData.enterpriseConcatBusinessCard" :src="publicUserData.enterpriseConcatBusinessCard" style="width: 100%;" alt="">
            </p>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="publicUserVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <previewDialog ref="previewDialog" client/>
    </div>
</template>
<script>
import previewDialog from '@/pages/project/previewDialog.vue'
const coverMap = {
	1: require('@/assets/fileIcon/pdf.png'),
	2: require('@/assets/fileIcon/word.png'),
	3: require('@/assets/fileIcon/xsl.png')
}
const attachmentMaps = {
	1: '项目BP',
	2: '访谈纪要',
	3: '立项报告',
	4: '尽调报告',
	5: '投决报告',
	6: '联系方式'
}
const coin = ['铁币', '铜币', '银币', '金币','黄钻','红钻']
export default {
    name: 'projectDetail',
    data() {
        const formatSize = (data) => {
            const val = Number(data || 0)
            if (!val) return '0 B';
            const k = 1024;
            const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
            const i = Math.floor(Math.log(val) / Math.log(k));
            return parseFloat((val / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        }
        return {
            publicUserVisible: false,
            publicUserLoading: false,
            publicUserData: {},
            coin,
            attachmentMaps,
            formatSize,
            coverMap,
            tab: '1',
            detail: {},
            countData: {}
        }
    },
    components: {
        previewDialog
    },
    computed: {
        tabOptions() {
            const { countData } = this
            const keys = ['bpCount' ,'interviewSummaryCount', 'projectProposalReportCount', 'dueDiligenceReportCount', 'investmentDecisionReportCount', 'enterpriseConcatCount']
            return ['项目bp', '访谈纪要', '立项报告', '尽调报告', '投决报告', '联系方式'].map((label, index) => {
                const count = countData[keys[index]] || 0
                return {
                    label,
                    value: index+1,
                    count,
                }
            })
        },
        fileList() {
            const { tab } = this
            const { investProjectAttachments = [] } = this.detail
            return investProjectAttachments.filter(({attachmentType}) => attachmentType == tab)
        }
    },
    async mounted() {
        await this.$nextTick()
        await this.$nextTick()
        await this.$store.state.clientLoginRef.init({ showClose: false })
        const { query = {} } = this.$route
        console.log(query)
        Object.keys(query).forEach(key => {
            this.$set(this.detail, key, query[key])
        })
        await this.$nextTick()
        document.querySelector('.public-view').scrollTo(0, 0)
        await this.$store.state.clientLoginRef.init({ showClose: false })
        this.getDetail()
    },
    methods: {
        errorload(e) {
            console.log('err', e)
        },
        onload(e) {
            console.log('load===>', e)
        },
        getFilest(key) {
            const { investProjectAttachments = [] } = this.detail
            return investProjectAttachments.filter(({attachmentType}) => attachmentType == key)
        },
        toHome() {
            this.$router.push({
                path:'/'
            })
        },
        collect() {
            const { collected } = this.detail
            const requestUrl = collected ? this.$api.cancelCollect : this.$api.addCollectRecord
            const methods = collected ? 'DeleteByData' : 'Post'
            this.$http[methods](requestUrl, {
                projectId: this.detail.id,
				projectName: this.detail.enterpriseName
            }).then(({ code, msg }) => {
                if (code == 200) {
                    this.$message.success(msg)
                    this.getDetail()
                }
            })
        },
        getDetail() {
            this.$http.Get(this.$api.clientProjectDetail,{
                id: this.$route.query.id
            }).then(({ data }) => {
                this.detail = data
            })  
        },
        async preview(item) {
            // const { code, msg } = await this.$http.Post(this.$api.downloadAttachment, {
            //     attachmentId:item.id,
            // });

            // if (code != 200) return this.$message.error(msg)
            this.$refs.previewDialog.open(item. id)
        },
        async lookEnterpriseConcat(data) {
            // this.$http.Post(this.$api.getEnterpriseContact, { id }).then(({ code, msg }) => {
            //     if (code === 200) {
            //         this.$message.success(msg)
            //         this.getDetail()
            //     }
            // })
            if (data.isPublic) {
                this.publicUserLoading = true
                this.$http.Post(this.$api.getEnterpriseContact, {id: data.id}).then(({ code, data }) => {
                    if (code == 200) {
                        this.publicUserData = data
                        this.publicUserVisible = true
                    }
                }).finally(() => {
                    this.publicUserLoading = false
                })
                return
            }
			if (data.mine || data.hasDownload) {
				if (!data.enterpriseConcatBusinessCard) return this.$message.warning('暂无名片')
                const h = this.$createElement;
				return this.$msgbox({
                    title: '名片',
                    message: h('img', {
                    attrs: {
                        src: data.enterpriseConcatBusinessCard
                    },
                    style: 'width: 390px;height: auto;' }),
                    showCancelButton: false
                })
            
			}
            this.$confirm(`使用1红钻兑换${this.attachmentMaps[data.attachmentType]}`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                
                this.$http.Post(this.$api.getEnterpriseContact, {id: data.id}).then(({ code }) => {
                    if (code == 200){
                        // const { enterpriseConcatName, enterpriseConcatPhone, enterpriseConcatBusinessCard } = data
                        // this.attachmentList[6].list[0] = { ...this.attachmentList[6].list[0], enterpriseConcatName, enterpriseConcatPhone, enterpriseConcatBusinessCard }
                        // this.showConcateBtn = true
                        this.getDetail()
                    }
                })
            })
            
        },
        async downLoad(item) {
        // const params = {
        //   id:item.id,
        //   attachmentType:item.attachmentType,
        // }
            // let {data} = await this.$http.Post(this.$api.downloadAttachment,  params );
            this.$confirm(`下载需要消耗1个${this.coin[item.attachmentType - 1]}兑换${this.attachmentMaps[item.attachmentType]}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                
                const { code, data: fileKey, msg } = await this.$http.Post(this.$api.downloadAttachment, {
                    attachmentId:item.id,
                });

                if (code != 200) return this.$message.error(msg)
                
                const notify = this.$notify({
                    title: '下载中',
                    message: '请稍等...',
                    duration: 0
                });
                const { data: { data, filename } } = await this.$http.DownLoadFile(this.$api.downloadFileByKey, {
                    fileKey
                })
                // download(data, filename)
                const methods = new this.Global()
                // console.log(data)
                methods.downloadBlob(data, decodeURIComponent(filename))
                this.$message({
                    type: 'success',
                    message: '操作成功'
                }); 
                notify.close()
            }).catch((e) => {
                console.log(e)
                this.$message({
                type: 'info',
                message: '已取消'
                });          
            });
        }
    }
}
</script>
<style lang="less" scoped>
.projectDetail{
    // width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 170px);
    // background-color: #fff;
    
    &-breadcrumb {
        // background-color: #409EFF;
        font-size: 14px;
        padding: 15px 0;
        background-color: #fff;

        .box {
            width: 1125px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            color: #2E3742;
            span{
                color: #95A1AF;
            }
            i {
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
    &-header{
        width: 100%;
        background-color: #fff;
        padding: 25px 0;
        &-container{
            width: 1125px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            .cover{
                width: 100px;
                height: 100px;
                border-radius: 8px;
                margin-right: 20px;
                /deep/ .error{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 30px;
                    background: #f5f7fa;
                    color: #909399;
                }
            }
            .title{
                color: #2e3742;
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 5px;
            }
            .industryName{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                span{
                    background-color: rgba(28, 104, 255, 0.05);
                    height: 20px;
                    padding: 0 6px;
                    border: 1px solid rgba(28, 104, 255);
                    border-radius: 5px;
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #1C68FF;
                    font-weight: 500;
                    & +span{
                        margin-left: 12px;
                    }
                }
            }
            .businessIncomeName{
                display: flex;
                color: #2e3742;
                font-size: 14px;
                align-items: center;
                .line{
                    margin: 0 16px;
                }
                span{
                    display: flex;
                    align-items: center;
                    img{
                        width: 16px;
                        height: 16px;
                        margin-right: 7px;
                    }
                    .icon{
                        font-size: 18px;
                        color: #2E3742;
                        margin-right: 7px;
                    }
                }
            }
            .projectDescription{
                font-size: 14px;
                margin-bottom: 10px;
            }
        }
    }

    &-body{
        width: 1125px;
        margin: 20px auto 0;
        gap: 0 10px;
        .el-empty{
            padding: 0;
        }
        &-item{
            padding: 24px 32px;
            background-color: #fff;
            margin-bottom: 16px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            .title{
                width: 90px;
                margin-right: 20px;
                color: #2e3742;
                font-size: 16px;
            }
            .file-list{
                flex: 1;
                width: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 0 10px;
                &-item{
                    flex: 0 0 50%;
                    padding: 24px;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    .cover{
                        width: 40px;
                        height: 40px;
                        margin-right: 15px;
                    }
                    .right{
                        width: 0;
                        flex: 1;

                    }
                    .file-title{
                        color: #2e3742;
                        font-size: 16px;
                        margin-bottom: 6px;
                        display: flex;
                        align-items: center;
                        span{
                            white-space: nowrap;      /* 确保文本在一行内显示 */
                            overflow: hidden;         /* 隐藏溢出的内容 */
                            text-overflow: ellipsis;
                            flex: 1;
                            width: 0;
                        }
                        .public{
                            background-color: rgba(28, 104, 255, 0.05);
                            height: 20px;
                            padding: 0 6px;
                            border: 1px solid rgba(28, 104, 255);
                            border-radius: 5px;
                            font-size: 12px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #1C68FF;
                            font-weight: 500;
                        }
                    }
                    .file-size{
                        color: #95a1af;
                        font-size: 13px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .operate{
                            span{
                                cursor: pointer;
                            }
                            span, i {
                                color: #2e3742;
                                font-size: 14px;
                            }
                            i{
                                margin: 0 22px;
                            }
                        }
                    }
                }
                &-enterpriseConcat{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex: 1;
                    .line{
                        display: flex;
                        align-items: center;
                        flex: 1;
                        color: #2e3742;
                        font-size: 14px;
                        img{
                            width: 18px;
                            height: 18px;
                        }
                        .name{
                            color: #95a1af;
                            font-size: 14px;
                            margin: 0 7px;
                        }
                        &-card{
                            display: flex;
                            justify-content: end;
                            .showConcate{
                                padding: 8px 32px;
                                background-color: #1C68FF;
                                border-radius: 6px;
                            }
                        }
                    }
                }
            }
        }
    }

    .publicUser-line{
        line-height: 30px;
    }


    &-top{
        display: flex;
        padding: 20px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        .cover{
            width: 200px;
            height: 300px;
            box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        }
        .detail{
            margin-left: 20px;
            flex: 1;
            .enterpriseName{
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 20px;
            }
            .industry{
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                font-size: 14px;
                color: #888;
                .title{
                    font-size: 16px;
                    color: #333;
                    width: 120px;
                }
            }
        }
    }
}
</style>